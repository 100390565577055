'use strict'

module.exports = {
  MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiZXJpY3JzIiwiYSI6ImNrdjZwNzhuMTB3dHcyb253YjZqcHBpOHAifQ.v6PvL8-SDwOT6wFuGfYcJw',

  API_URL: "/api/v1",
  WS_URL: process.env.VUE_APP_WS_URL,

  TOKEN_STORAGE_KEY: 'token',
  PROFILE_STORAGE_KEY: 'profile'


}