import Vue from "vue"
import Router from "vue-router"
import auth from "./auth"

Vue.use(Router)


const requireAuthenticated = (to, from, next) => {
  if (!auth.isAuth()) { 
    console.log('!auth.isAuth') 
    next({name:'login'}) 
  } else { 
    next() 
  } 
} 
 
const redirectLogout = (to, from, next) => { 
  auth.logout() 
    .then(() => next({name:'login'})) 
}


let auth_routes = [
    {
      path: "login",
      name: "login",
      component: () => import("./views/AuthLogin"),
      meta: { title: 'Login', public: true }
    },
    {
      path: 'logout',
      name: 'logout',
      beforeEnter: redirectLogout
    },
    {
      path: "register",
      name: "register",
      component: () => import("./views/AuthRegister"),
      meta: { title: 'Register', public: true }
    },
    {
      path: "activate/:uid/:token",
      name: "activate",
      component: () => import("./views/AuthActivate"),
      meta: { title: 'Account Activation', public: true }
    },
    {
      path: "password/reset",
      name: "passwordreset",
      component: () => import("./views/AuthPasswordReset"),
      meta: { title: 'Password Reset', public: true }
    },
    {
      path: "password/reset/confirm/:uid/:token",
      name: "passwordresetconfirm",
      component: () => import("./views/AuthPasswordResetConfirm"),
      meta: { title: 'Password Reset Confirmation', public: true }
    },
]


let app_routes = [
    {
        path: "dashboard",
        name: "dashboard",
        component: () => import("./views/DashBoard"),
        meta: { title: 'Dashboard'}
    },
  {
    path: "character-list",
    name: "character-list",
    component: () => import("./views/CharacterList"),
    meta: { title: 'Characters'}
  },
  {
    path: "api-keys",
    name: "api-keys",
    component: () => import("./views/APIKeyList"),
    meta: { title: 'API Keys'}
  },
	{
    path: "bot-monitor",
		name: "bot-monitor",
		component: () => import("./views/BotMonitor"),
		meta: { title: 'Bot Monitor'}
	},
  {
    path: "bot-monitor/:id",
    name: "bot-monitor-specific",
    component: () => import("./views/BotMonitor"),
    meta: { title: 'Bot Monitor'}
  },
  {
    path: "character-detail/:id",
    name: "character-detail",
    component: () => import("./views/CharacterDetail"),
    meta: { title: 'Character Detail'}
  },  
  {
    path: "hero-calc/:id",
    name: "hero-calc",
    component: () => import("./views/HeroPointCalc"),
    meta: { title: "Hero Point Calculations"}
  },
  {
    path: "hero-calc",
    name: "hero-calc",
    component: () => import("./views/HeroPointCalc"),
    meta: { title: "Hero Point Calculations"}
  },
  {
    path: "quest-list",
    name: "quest-list",
    component: () => import("./views/QuestListView"),
    meta: { title: 'Quest List' }
  },
  {
    path: "char-quests/:id",
    name: "char-quests",
    component: () => import("./views/CharQuestsView")
  },
  // admin stuff
  {
    path: "user",
    name: "user-list",
    component: () => import("./views/UserList"),
    meta: { title: 'Users' }
  }



]

let router = new Router({
    mode: "history",
    routes: [
    {
      path: "/auth",
      meta: { },
      component: () => import("./layouts/BlankLayout"),
      children: auth_routes
    },

      {
        path: '/',
        name: 'home',
        redirect: { name: 'dashboard' },
        meta: {
          // Everything under /app requires authentication
          requiresAuth: true,
          title: 'App'
        },
        component: () => import("./layouts/AppLayout"),
        children: app_routes
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import("./views/NotFound")
      }
    ]
  })

router.beforeEach((to, from, next) => {
  console.log("beforeEach to:",to)
  if (!to.meta.public){
    console.log('requireAuthenticated')
    requireAuthenticated(to, from, next)
  }

  next()
})

router.beforeResolve((to, from, next) => { 
  //router.app.$root.title = to.meta.title 
  next() 
})

  
router.afterEach((to, from) => {
    if ('title' in to.meta){
      router.app.$root.title = to.meta.title
    }
    console.log(from)
  })
  
  
export default router
  
