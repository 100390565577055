<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<template>
  <router-view></router-view>
</template>


<script>
//import http from './http-common'

export default {
  mounted() {
    const theme = localStorage.getItem("dark_theme")

    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true
        this.$root.darkmode = true
      } else {
        this.$vuetify.theme.dark = false
        this.$root.darkmode = false
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true
      this.$root.darkmode = true
      localStorage.setItem(
        "dark_theme",
        this.$vuetify.theme.dark.toString()
      );
    }

    // Get api version
   // http.get('info')
     // .then((res) => {
        //this.$root.API_VERSION = res.data.version
      //})
  },
  data () {
    return {

    }
  }
}
</script>
