import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import './registerServiceWorker'
import '@mdi/font/css/materialdesignicons.css'
import VueChatScroll from 'vue-chat-scroll'
//https://stackoverflow.com/questions/65878999/vue-router-push-error-avoided-redundant-navigation-to-current-location
import VueRouter from "vue-router"
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {console.log(error)
  });
};

Vue.config.productionTip = false

const DEFAULT_TITLE = "Kallisti"

Vue.use(VueChatScroll)

new Vue({
  data: {
    title: 'no title',

    darkmode: true,

    DEFAULT_TITLE: DEFAULT_TITLE,
  },
  computed: {
    titleFromCrumbs () {
      return this.crumbs.map(x => x.text).join(" / ")
    }
  },
  watch: {
    title () {
      document.title = this.title + ' - ' + DEFAULT_TITLE
    }
  },
  router, 
  vuetify,
  render: h => h(App)
}).$mount('#app')

